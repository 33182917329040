import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Header from "../../../../containers/Header";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import HowJoin from "../../../../assets/images/howto/how-to-join-banner.jpg";
import HowJoinMbl from "../../../../assets/images/howto/how-to-join.png";
import {
  addMetaTags,
  openGraphTags,
  twitterCardTags,
} from "../../../../utils/addMetaTags";

const HowToJoin = () => {
  const { isAuth } = useSelector((state) => state.auth);

  useEffect(() => {
    updateMetaTags();
  }, []);

  const updateMetaTags = () => {
    addMetaTags({
      title: "Betrich - How to Join?",
      description:
        "BetRich.io offers a premier experience in online sports betting and casino gaming. Enjoy a wide range of sports events, live betting, and top casino games with fast payouts and secure transactions.",
      keywords:
        "Initial Coin Offering Through Betting, Crypto Betting, Crypto Casino, Virtual Asset Casino, Online Sports Betting, Casino Gaming, Live Betting, Secure Online Gambling, Poker, Slots Games, Cricket, Tennis, Andar Bahar, Fast Payouts, Sports Events, Online Casino Games, Betting Platform, Safe Gambling, Trusted Betting Site",
      metaIamge: "../../../../assets/images/seo/meta_tags_1200_630.png",
      metaUrl: "https://www.betrich.io/",
    });

    openGraphTags({
      ogTitle: "Betrich - How to Join?",
      ogDescription:
        "BetRich offers a premier experience in online sports betting and casino gaming. Enjoy a wide range of sports events, live betting, and top casino games with fast payouts and secure transactions.",
      ogImage: "../../../../assets/images/seo/og_tags_1024_512.png",
      ogUrl: "https://www.betrich.io",
      ogType: "website",
      ogKeywords:
        "Initial Coin Offering Through Betting, Crypto Betting, Crypto Casino, Virtual Asset Casino, Online Sports Betting,Casino Gaming, Live Betting, Secure Online Gambling, Poker, Slots Games, Cricket, Tennis, Andar Bahar, Fast Payouts, Sports Events, Online Casino Games, Betting Platform, Safe Gambling, Trusted Betting Site",
    });

    twitterCardTags({
      twitterCard: "summary_large_image",
      twitterTitle: "Betrich - How to Join?",
      twitterDescription:
        "BetRich offers a premier experience in online sports betting and casino gaming. Enjoy a wide range of sports events, live betting, and top casino games with fast payouts and secure transactions.",
      twitterImage: "../../../../assets/images/seo/meta_tags_1200_630.png",
      twitterSite: "@YourTwitterHandle",
      twitterKeywords:
        "Initial Coin Offering Through Betting, Crypto Betting, Crypto Casino, Virtual Asset Casino, Online Sports Betting, Casino Gaming, Live Betting, Secure Online Gambling, Poker, Slots Games, Cricket, Tennis, Andar Bahar, Fast Payouts, Sports Events, Online Casino Games, Betting Platform, Safe Gambling, Trusted Betting Site",
    });
  };

  return (
    <>
      {isAuth ? <HeaderAfterLogin /> : <Header />}

      <main className="main how-to-page how-join">
        <img src={HowJoin} alt="how to join" className="d-none d-sm-block" />
        <img src={HowJoinMbl} alt="how to join" className="d-sm-none" />
        <div className="container">
          <div className="how-to-content">
            <div className="topHead">
              <strong>WELCOME USERS, </strong>
              <span>
                TODAY WE WILL LEARN HOW TO SIGNUP & PLAY GAMES ON BETRICH.IO.
              </span>
            </div>

            <h4>How to Sign-up?</h4>
            <p className="pl-25">
              1. Click on "SignUp": (Available in the upper right corner of your
              screen)
            </p>

            <p>
              <b>You have two options available to create an account</b>
            </p>
            <p className="pl-50">
              1- Create account through Instant ID Using Whastapp.
            </p>
            <p className="pl-50">2- By Filling the Details.</p>

            <p className="mt-4">
              <b>
                1- You can also use the instant id feature to create an account
                instantly using whatsapp
              </b>
            </p>
            <ul className="pl-40">
              <li>
                Just Click on it (It will take you to our whatsapp ask them team
                for the account and team will provide you one after taking some
                details) that’s set you are goo to go.
              </li>
            </ul>

            <p className="mt-4">
              <b>2- Fill the following details:</b>
            </p>
            <ul className="pl-40">
              <li>Username:</li>
              <li>Password:</li>
              <li>Confirm your password again:</li>
              <li>
                Select Country Code: (Select the country From which you are
                creating account):
              </li>
              <li>Enter Mobile Number:</li>
              <li>
                Click on the "Get OTP” (You will receive an OTP on your provided
                mobile number):
              </li>
              <li>Enter the OTP and click verify OTP:</li>
              <li>After this Just click on "REGISTER":</li>
            </ul>
            <p className="mt-3">
              Than you will get a Dialogue with "Successful registration" on
              your screen
            </p>

            <h4 className="mt-5">How to Login?</h4>
            <p>
              Click on <b>"Login"</b>: (Available in the upper right corner of
              your screen)
            </p>

            <p className="mt-4">
              <b>Fill the following details:</b>
            </p>
            <ul className="pl-40">
              <li>Username:</li>
              <li>Password:</li>
              <li>After this Just Click on "Login"</li>
            </ul>

            <p className="mt-3">
              You will successfully login to your account with a dialogue with
              "Login Successful” on your screen.
            </p>

            <p className="mt-4">
              <b>"Enjoy your Games"</b>
            </p>
          </div>
        </div>
      </main>

      {!isAuth ? <BeforeLoginFooter /> : <Footer />}
    </>
  );
};

export default HowToJoin;
