import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Header from "../../../../containers/Header";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import HowWithdraw from "../../../../assets/images/howto/how-to-withdraw-banner.jpg";
import HowWithdrawMbl from "../../../../assets/images/howto/how-to-withdraw.png";
import {
  addMetaTags,
  openGraphTags,
  twitterCardTags,
} from "../../../../utils/addMetaTags";

const HowToWithdraw = () => {
  const { isAuth } = useSelector((state) => state.auth);

  useEffect(() => {
    updateMetaTags();
  }, []);

  const updateMetaTags = () => {
    addMetaTags({
      title: "Betrich - How to withdraw?",
      description:
        "BetRich.io offers a premier experience in online sports betting and casino gaming. Enjoy a wide range of sports events, live betting, and top casino games with fast payouts and secure transactions.",
      keywords:
        "Initial Coin Offering Through Betting, Crypto Betting, Crypto Casino, Virtual Asset Casino, Online Sports Betting, Casino Gaming, Live Betting, Secure Online Gambling, Poker, Slots Games, Cricket, Tennis, Andar Bahar, Fast Payouts, Sports Events, Online Casino Games, Betting Platform, Safe Gambling, Trusted Betting Site",
      metaIamge: "../../../../assets/images/seo/meta_tags_1200_630.png",
      metaUrl: "https://www.betrich.io/",
    });

    openGraphTags({
      ogTitle: "Betrich - How to withdraw?",
      ogDescription:
        "BetRich offers a premier experience in online sports betting and casino gaming. Enjoy a wide range of sports events, live betting, and top casino games with fast payouts and secure transactions.",
      ogImage: "../../../../assets/images/seo/og_tags_1024_512.png",
      ogUrl: "https://www.betrich.io",
      ogType: "website",
      ogKeywords:
        "Initial Coin Offering Through Betting, Crypto Betting, Crypto Casino, Virtual Asset Casino, Online Sports Betting,Casino Gaming, Live Betting, Secure Online Gambling, Poker, Slots Games, Cricket, Tennis, Andar Bahar, Fast Payouts, Sports Events, Online Casino Games, Betting Platform, Safe Gambling, Trusted Betting Site",
    });

    twitterCardTags({
      twitterCard: "summary_large_image",
      twitterTitle: "Betrich - How to withdraw?",
      twitterDescription:
        "BetRich offers a premier experience in online sports betting and casino gaming. Enjoy a wide range of sports events, live betting, and top casino games with fast payouts and secure transactions.",
      twitterImage: "../../../../assets/images/seo/meta_tags_1200_630.png",
      twitterSite: "@YourTwitterHandle",
      twitterKeywords:
        "Initial Coin Offering Through Betting, Crypto Betting, Crypto Casino, Virtual Asset Casino, Online Sports Betting, Casino Gaming, Live Betting, Secure Online Gambling, Poker, Slots Games, Cricket, Tennis, Andar Bahar, Fast Payouts, Sports Events, Online Casino Games, Betting Platform, Safe Gambling, Trusted Betting Site",
    });
  };

  return (
    <>
      {isAuth ? <HeaderAfterLogin /> : <Header />}

      <main className="main how-to-page how-withdraw">
        <img
          src={HowWithdraw}
          alt="how to join"
          className="d-none d-sm-block"
        />
        <img src={HowWithdrawMbl} alt="how to join" className="d-sm-none" />
        <div className="container">
          <div className="how-to-content">
            <div className="topHead">
              <strong>WELCOME USERS, </strong>
              <span>
                TODAY WE WILL LEARN HOW TO WITHDRAW EARNING ON BETRICH.IO.
              </span>
            </div>

            <h4>How to Withdraw?</h4>
            <p>Login to your account first</p>
            <p>Click on "Withdraw": (Available in your profile menu as)</p>

            <p>
              <b>Follow the steps:</b>
            </p>
            <p>
              <b>Two Deposit Options are available</b>
            </p>
            <p>
              <b>1-Add Bank Details:</b> : (You can add your Bank details to use
              them later and permanently no need to enter again and again when
              you came to withdraw your funds)
            </p>
            <p>
              <b>2-Add Withdraw Request:</b>(Here you make a request to withdraw
              funds using added bank details or using UPI)
            </p>

            <p className="mt-3">
              Click on the particular method, enter amount and click “Submit”
              Done you will receive your funds in your account with in some time
              after verification.
            </p>
          </div>
        </div>
      </main>

      {!isAuth ? <BeforeLoginFooter /> : <Footer />}
    </>
  );
};

export default HowToWithdraw;
